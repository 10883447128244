const COOKIE_CONSENT_COOKIE_NAME = 'cc';
const COOKIE_CONSENT_BANNER_ID = 'cookie-consent';

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = name => {
  if (!name) {
    return document.cookie;
  }

  // eslint-disable-next-line
  name = name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1');

  const matches = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`));

  return matches ? decodeURIComponent(matches[1]) : null;
};

const closeCookieConsent = () => {
  const banner = document.getElementById(COOKIE_CONSENT_BANNER_ID);
  banner.style.display = 'none';
  setCookie(COOKIE_CONSENT_COOKIE_NAME, '1', 365);
  const buttonUp = document.getElementById('button-up');
  buttonUp.style.top = 'calc(100vh - 80px)';
};

export const initCookieConsent = () => {
  const didIShowThisStupidBanner = getCookie(COOKIE_CONSENT_COOKIE_NAME);
  if (didIShowThisStupidBanner === '1') return;

  const closeButton = document.querySelector(
    `#${COOKIE_CONSENT_BANNER_ID} > div > a.close-icon`,
  );
  if (closeButton) {
    closeButton.addEventListener('click', closeCookieConsent);
  }

  const banner = document.getElementById(COOKIE_CONSENT_BANNER_ID);
  banner.style.display = 'block';
  const buttonUp = document.getElementById('button-up');
  const buttonUpOffset = banner.clientHeight + 80;
  buttonUp.style.top = `calc(100vh - ${buttonUpOffset}px)`;
};
