const handleBurgerButtonClick = () => {
  document.body.classList.toggle('mobile-menu-active');
};

const closeBurgerMenu = () => {
  document.body.classList.remove('mobile-menu-active');
};

export const initBurgerMenu = () => {
  const burgerButton = document.getElementById('burger-button');

  burgerButton.addEventListener('click', handleBurgerButtonClick);
};

window.addEventListener('resize', closeBurgerMenu);
