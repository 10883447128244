import { initCarousel } from './carousel';
// import { initJobPosts } from './job-posts';
import { initAuthState } from './auth';
import { initCookieConsent } from './cookies';
import { initBurgerMenu } from './burger-menu';
// import { initTheme } from './theme';
import { initFontTracker } from './font-tracker';

const init = () => {
  initAuthState();
  // initTheme();
  initCookieConsent();
  initCarousel();
  // initJobPosts();
  initBurgerMenu();
  initFontTracker();
};

document.addEventListener('DOMContentLoaded', init);
