import { stringify } from 'qs';
import { nanoid } from 'nanoid';
import { API_ORIGIN } from './constants';

const url = '/t/e';

const CID_KEY = '_pc';

export const createPalantir = () => {
  let context = {};

  const getCid = () => {
    let cid = null;

    if (global.sessionStorage) {
      cid = global.sessionStorage.getItem(CID_KEY);

      if (!cid) {
        cid = nanoid();

        global.sessionStorage.setItem(CID_KEY, cid);
      }
    }

    return cid;
  };

  const trackEvent = ({ v1, v2, v3, v4 }) => {
    const image = new Image();

    const c = getCid();

    const searchString = stringify(
      { ...context, c, v1, v2, v3, v4, r: Math.random() },
      { addQueryPrefix: true },
    );

    image.src = `${API_ORIGIN}${url}${searchString}`;
  };

  const setContext = (_context = {}) => {
    if (typeof _context !== 'object' || _context === null) {
      throw new Error('palantir context should be an object!');
    }

    context = _context;
  };

  return { setContext, getCid, trackEvent };
};
