const PRODUCTION = 'production';
const PREPROD = 'preprod';
const STAGING = 'staging';
const LOCAL = 'local';

const envsByOrigin = {
  'https://www.ego.jobs': PRODUCTION,
  'https://ego.jobs': PRODUCTION,
  'https://www.preprod.ego.jobs': PREPROD,
  'https://www.preprod--ego-jobs.netlify.app': PREPROD,
  'https://preprod--ego-jobs.netlify.app': PREPROD,
  'https://www.staging--ego-jobs.netlify.app': STAGING,
  'https://www.staging.ego.jobs': STAGING,
  'https://staging--ego-jobs.netlify.app': STAGING,
  'http://localhost:1234': LOCAL,
};

const ENV = envsByOrigin[global.location.origin] || STAGING;

const apiOrigins = {
  [PRODUCTION]: 'https://api.ego.jobs',
  [PREPROD]: 'https://preprod.api.ego.jobs',
  [STAGING]: 'https://staging.api.ego.jobs',
  [LOCAL]: 'http://localhost:5100',
};

export const API_ORIGIN = apiOrigins[ENV];
export const IS_PROD = ENV === PRODUCTION;
