import { t, lang } from './i18n';

const shuffleArray = array => {
  const arrayCopy = [...array];
  for (let i = arrayCopy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
  }

  return arrayCopy;
};

// slide keys: 'job-hunt', 'middleman', 'difference', 'no-bias'

const CAROUSEL_SLIDE_JOB_HUNT = 'job-hunt';
const CAROUSEL_SLIDE_MIDDLEMAN = 'middleman';
const CAROUSEL_SLIDE_DIFFERENCE = 'difference';
const CAROUSEL_SLIDE_NO_BIAS = 'no-bias';

const CAROUSEL_SLIDES_KEYS = [
  CAROUSEL_SLIDE_JOB_HUNT,
  ...shuffleArray([
    CAROUSEL_SLIDE_MIDDLEMAN,
    CAROUSEL_SLIDE_DIFFERENCE,
    CAROUSEL_SLIDE_NO_BIAS,
  ]),
];
const SLIDES_LENGTH = CAROUSEL_SLIDES_KEYS.length;

let currentCarouselIndex = 0;

var xDown = null;
var yDown = null;

const updateActiveBlock = newIndex => {
  currentCarouselIndex = newIndex;
  if (newIndex > SLIDES_LENGTH - 1) {
    currentCarouselIndex = 0;
  } else if (newIndex < 0) {
    currentCarouselIndex = SLIDES_LENGTH - 1;
  }
  window.location.hash = CAROUSEL_SLIDES_KEYS[currentCarouselIndex];

  const carouselInnerElement = document.querySelector('#carousel > .inner');
  carouselInnerElement.style.transform = `translateX(${
    -currentCarouselIndex * window.innerWidth
  }px)`;

  Array.from(document.getElementsByClassName('dot')).forEach(
    (element, index) => {
      element.classList.remove('active');
      if (index === currentCarouselIndex) {
        element.classList.add('active');
      }
    },
  );
};

const updateActiveBlockWithStep = step => {
  updateActiveBlock(currentCarouselIndex + step);
};

const handleDotClick = dotIndex => {
  updateActiveBlock(dotIndex);
};

const handleTouchStart = evt => {
  const firstTouch = evt.touches[0];
  xDown = firstTouch.clientX;
  yDown = firstTouch.clientY;
};

const handleTouchMove = evt => {
  if (!xDown || !yDown) {
    return;
  }

  var xUp = evt.touches[0].clientX;
  var yUp = evt.touches[0].clientY;

  var xDiff = xDown - xUp;
  var yDiff = yDown - yUp;

  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    if (xDiff >= 10) {
      updateActiveBlockWithStep(1);
    } else if (xDiff <= -10) {
      updateActiveBlockWithStep(-1);
    }
  }

  xDown = null;
  yDown = null;
};

const createCarouselSlide = slideKey => {
  const slideElement = document.createElement('div');
  slideElement.classList.add('block', `block-${slideKey}`);
  const slideInner = document.createElement('div');
  slideInner.classList.add('block-inner');

  const title = document.createElement('h1');
  title.textContent = t(`carousel.block-${slideKey}.title`);
  slideInner.appendChild(title);

  const subTitle = document.createElement('h2');
  subTitle.textContent = t(`carousel.block-${slideKey}.subTitle`);
  slideInner.appendChild(subTitle);

  const button = document.createElement('a');
  button.classList.add('button');
  button.href = `/${lang}/contact`;
  button.textContent = t('button.getStarted');
  slideInner.appendChild(button);

  slideElement.appendChild(slideInner);

  return slideElement;
};

export function initCarousel() {
  const hash = window.location.hash.substring(1);
  const hashIndex = CAROUSEL_SLIDES_KEYS.findIndex(
    slideKey => slideKey === hash,
  );
  if (hashIndex !== -1) {
    currentCarouselIndex = hashIndex;
  }

  const carouselElement = document.getElementById('carousel');
  const carouselInnerElement = document.querySelector('#carousel > .inner');
  carouselInnerElement.style.transform = `translateX(${
    -currentCarouselIndex * window.innerWidth
  }px)`;
  const dotsElement = document.createElement('div');
  dotsElement.classList.add('dots');

  CAROUSEL_SLIDES_KEYS.forEach((key, index) => {
    const slideElement = createCarouselSlide(key, index);
    carouselInnerElement.appendChild(slideElement);

    const dotElement = document.createElement('div');
    dotElement.classList.add('dot');
    if (index === currentCarouselIndex) {
      dotElement.classList.add('active');
    }
    dotElement.addEventListener('click', handleDotClick.bind(this, index));
    dotsElement.appendChild(dotElement);
  });

  carouselElement.appendChild(dotsElement);

  carouselElement.addEventListener('touchstart', handleTouchStart, false);
  carouselElement.addEventListener('touchmove', handleTouchMove, false);

  const arrowLeft = document.getElementById('arrow-left');
  arrowLeft.addEventListener('click', updateActiveBlockWithStep.bind(this, -1));

  const arrowRight = document.getElementById('arrow-right');
  arrowRight.addEventListener('click', updateActiveBlockWithStep.bind(this, 1));
}
