import { IS_PROD } from './constants';

export function initFontTracker() {
  if (IS_PROD) {
    const fontTracker = document.createElement('link');
    fontTracker.rel = 'stylesheet';
    fontTracker.href =
      'https://fast.fonts.net/lt/1.css?apiType=css&c=970dfce3-8deb-454c-a6df-f2d6829e4ce0&fontids=5760841,5760915,5761058';
    document.head.appendChild(fontTracker);
  }
}
