import jwtDecode from 'jwt-decode';
import { createPalantir } from './palantir';
import { getCookie } from './cookies';

const TOKEN_COOKIE_NAME = 't';
const CSS_BREAKPOINTS = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1280,
  XXL: 1440,
};

const BRANCH = process.env.BRANCH;
const IS_DEV = global.location.href.includes('1234');

const getAuthHeaders = () => {
  const cookie = getCookie(TOKEN_COOKIE_NAME);

  let accessToken = null;

  let fbase = null;

  let refreshToken = null;

  let fbaseRefreshToken = null;

  try {
    ({
      accessToken,
      refreshToken,
      fbase: { idToken: fbase, refreshToken: fbaseRefreshToken },
    } = JSON.parse(cookie));
  } catch (e) {
    return { headers: null };
  }

  return {
    accessToken,
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      fbase,
    }),
    refreshToken,
    fbaseRefreshToken,
  };
};

// const getRefreshedHeaders = async () => {
//   const { refreshToken, fbaseRefreshToken } = getAuthHeaders();
//   if (refreshToken && fbaseRefreshToken) {
//     const refreshRes = await fetch(`${API_ORIGIN}/user/refresh-token`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ refreshToken }),
//     });
//     const refreshFbaseRes = await fetch(`${API_ORIGIN}/fbase/refresh`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ refreshToken: fbaseRefreshToken }),
//     });
//     if (refreshRes.ok && refreshFbaseRes.ok) {
//       const oldCredentials = JSON.parse(getCookie(TOKEN_COOKIE_NAME));
//       const refreshData = await refreshRes.json();
//       const refreshFbaseData = await refreshFbaseRes.json();
//       const newCredentials = {
//         ...oldCredentials,
//         accessToken: refreshData.access_token,
//         idToken: refreshData.id_token,
//         expiresIn: refreshData.expires_in,
//         tokenType: refreshData.token_type,
//         fbase: { ...refreshFbaseData },
//       };
//       setCookie(TOKEN_COOKIE_NAME, JSON.stringify(newCredentials), 86400);
//       const { headers } = getAuthHeaders();

//       return headers;
//     }
//   }

//   return null;
// };

const loadUserData = () => {
  let { accessToken, headers } = getAuthHeaders();

  if (!headers || !accessToken) {
    return null;
  }

  const { sub: uid } = jwtDecode(accessToken);

  // let res = await fetch(`${API_ORIGIN}/user/get-name`, { headers });

  // if (res.status === 401) {
  //   headers = await getRefreshedHeaders();
  // }

  // if (!headers) {
  //   return null;
  // }

  // res = await fetch(`${API_ORIGIN}/user/get-name`, { headers });
  // const { userName } = await res.json();
  // const avatarRes = await fetch(`${API_ORIGIN}/user/get-avatar?uid=${uid}`, {
  //   headers,
  // });
  // const { avatarSrc } = await avatarRes.json();

  return { uid };
};

global.__testLoadUserData = loadUserData; // for test only

const getBreakpointByWidth = width => {
  if (width >= CSS_BREAKPOINTS.XXL) {
    return 'xxl';
  } else if (width >= CSS_BREAKPOINTS.XL) {
    return 'xl';
  } else if (width >= CSS_BREAKPOINTS.LG) {
    return 'lg';
  } else if (width >= CSS_BREAKPOINTS.MD) {
    return 'md';
  } else if (width >= CSS_BREAKPOINTS.MD) {
    return 'sm';
  } else {
    return 'xs';
  }
};

export const initAuthState = async () => {
  // const langPickers = document.querySelectorAll("[lang-picker]");
  // const onPickerClick = (e) => {
  //   document.body.classList.toggle("lang-picker-active");
  //   e.stopPropagation();
  // };

  const userData = loadUserData();

  let userId;

  if (userData) {
    const { uid } = userData;
    // const fallbackSrc = `https://boiling-inlet-60193.herokuapp.com/v1/flares/?name=${userName}`;
    // Array.from(document.getElementsByClassName("username")).forEach(
    //   (element) => {
    //     element.textContent = userName;
    //   }
    // );
    // Array.from(document.getElementsByClassName("avatar")).forEach((element) => {
    //   element.style.backgroundImage = `url("${avatarSrc}"), url("${fallbackSrc}")`;
    // });

    const profileButton = document.getElementById('open-app-button');
    profileButton.classList.remove('hidden');
    const signUpLinks = document.getElementById('sign-up-links');
    signUpLinks.classList.add('hidden');

    userId = uid;
  }
  const palantir = createPalantir();

  if (!IS_DEV && palantir) {
    palantir.setContext({
      u: userId,
      p: global.location.href,
      b: BRANCH,
      sr: `${window.screen.width}x${window.screen.height}`,
      vp: `${window.innerWidth}x${window.innerHeight}`,
      bp: getBreakpointByWidth(window.innerWidth),
    });
    palantir.trackEvent({ v1: 'pageview' });
  }

  // langPickers.forEach((el) => {
  //   el.addEventListener("click", onPickerClick);
  // });
};

// document.addEventListener("click", () => {
//   document.body.classList.remove("lang-picker-active");
// });
